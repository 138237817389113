export async function useWordpressLink(link, { fallbackLabel = '' } = {}) {
  if (!toValue(link)) {
    return { label: ref(''), url: ref(''), pending: ref(false), newTab: ref(false) }
  }

  const label = computed(() => toValue(link).label || toValue(fallbackLabel))
  const isRefLink = computed(() => toValue(link).mode === 'ref')
  const urlSuffix = computed(() =>
    toValue(link).enableUrlParam
      ? `${!/^[$#]/.test(toValue(link).hash) ? '?' : ''}${toValue(link).hash}`
      : '',
  )
  const newTab = computed(() => toValue(link).target)

  const { data: baseUrl, pending } = isRefLink.value
    ? await useWordpressData(`wp-remote/link/url/${toValue(link).ref.id}`)
    : { pending: ref(false), data: computed(() => toValue(link).text ?? '') }

  const url = computed(() => `${baseUrl.value ?? ''}${urlSuffix.value}`)

  return { label, url, pending, newTab }
}
