<template>
  <dl class="facts-table" :class="{ flexible, narrow, aligned, emphasized }">
    <template v-for="[key, value] in normalizedFacts">
      <Unwrap :if="!flexible">
        <div class="facts-row" :key="`wrap-${key}`">
          <dt class="fact-term" :key="`dt-${key}`">
            <slot name="key" v-bind="{ key, value }">{{ key }}</slot>
          </dt>
          <div class="fact-slash" aria-hidden="true" :key="`slash-${key}`"><Slash /></div>
          <dd class="fact-value" :key="`dd-${key}`">
            <slot name="value" v-bind="{ key, value }">{{ value }}</slot>
          </dd>
        </div>
      </Unwrap>
    </template>
  </dl>
</template>

<script setup>
let props = defineProps({
  facts: Array,
  narrow: Boolean,
  flexible: Boolean,
  aligned: Boolean,
  emphasized: Boolean,
})

let normalizedFacts = computed(() =>
  props.facts.map(fact => (!Array.isArray(fact) ? [fact.label, fact.value] : fact)),
)
</script>

<style lang="scss" scoped>
@function base-size($factor: 1) {
  @return calc(#{$factor} * var(--base-size, 1rem));
}

.facts-table {
  --base-size: 12px;

  margin: 0;
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto auto;
  row-gap: base-size(0.3);

  line-height: 1;

  @include mediaSM {
    --base-size: 16px;
  }

  &.flexible {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .facts-row {
    display: flex;
  }

  .fact-term,
  .fact-slash,
  .fact-value {
    --padding-y-start: #{base-size(1.1)};
    --padding-y-end: #{base-size(0.9)};

    display: flex;
    align-items: center;
    padding: var(--padding-y-start, 0) var(--padding-x, 0) var(--padding-y-end, 0);
    background-color: rgb(0 0 0 / 75%);
  }

  .fact-slash {
    --padding-y-start: #{base-size(1)};
    --padding-y-end: #{base-size(1)};
    --padding-x: #{base-size(0.75)};

    --slash-size: #{base-size(1.5)};
    --slash-color: var(--primary-color);
  }

  &.narrow {
    .fact-term,
    .fact-slash,
    .fact-value {
      --padding-y-start: #{base-size(0.55)};
      --padding-y-end: #{base-size(0.45)};
    }

    .fact-slash {
      --padding-y-start: #{base-size(0.4)};
      --padding-y-end: #{base-size(0.6)};
    }
  }

  .fact-term {
    text-transform: uppercase;
    font-size: base-size(1);
    padding-left: base-size(1);
    line-height: 0;
    letter-spacing: 2px;
  }

  &.aligned {
    .fact-term {
      padding-left: 0;
    }
  }

  .fact-value {
    margin: 0;
    font-size: base-size(1.5);
    font-weight: bold;
    padding-left: base-size(0.3);
    padding-right: base-size(2.25);
    width: fit-content;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &.emphasized .fact-value {
    text-transform: uppercase;
    color: var(--primary-color);
  }
}
</style>
