<template>
  <div class="loading-overlay">
    <div class="loading-indicator">
      <slot><AnimatedCross /></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--primary-color);
  z-index: 99999;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: clamp(24px, 0.75rem + 6vw, 40px);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.loading-indicator {
  z-index: 20001;
  color: #000;
  opacity: 0;
  transition: opacity ease 500ms;
  opacity: 1;
}
</style>
