<script setup>
let props = defineProps({
  button: Object,
})

let { label, url, pending, newTab } = await useWordpressLink(toRef(props.button.value, 'link'), {
  fallbackLabel: toRef(props.button, 'label'),
})
</script>

<template>
  <ArrowLink v-if="!pending" :to="url" :new-tab="newTab">{{ label }}</ArrowLink>
</template>

<style lang="scss" scoped></style>
