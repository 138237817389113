<script setup>
let props = defineProps({
  image: Array,
  headline: String,
  headlineFlags: Object,
  text: String,
  alignment: String,
  display: {
    type: String,
    default: 'fullscreen',
  },
})

let parallax = computed(() => props.display === 'parallax')
let hasHeadline = computed(() => (props.headline ?? '').trim().length > 0)
let hasText = computed(
  () => props.text.trim().replace('<p>&nbsp;</p>', '').replace('<br />', '').length > 0,
)
let hasContent = logicOr(hasHeadline, hasText)

let { data: imageMeta, pending } = await useWordpressData(
  `wp-remote/image/meta/${props.image[0].id}`,
)

let imageUrl = computed(() => imageMeta.value?.url)
let aspectRatio = computed(() =>
  imageMeta.value ? imageMeta.value.width / imageMeta.value.height : undefined,
)

let loadProgress = ref()
let progressStore = useProgressStore()
progressStore.track(useProgress(loadProgress, 'Hero Image'))
</script>

<template>
  <FullscreenImageContainer
    v-if="!pending"
    :image="imageUrl"
    :aspect-ratio="display !== 'proportional' ? undefined : aspectRatio"
    :parallax="parallax ? '-45vh' : undefined"
    @update:loaded="loadProgress = true"
  >
    <div
      v-if="hasContent"
      class="wpce-hero-image"
      :class="{ 'only-headline': hasHeadline && !hasText }"
    >
      <div
        v-if="hasText"
        class="wpce-hero-image-content-container container"
        :class="!hasHeadline ? alignment : undefined"
      >
        <ContentBox v-if="hasText" class="wpce-hero-image-content" v-enter
          ><div v-html="text"></div
        ></ContentBox>
      </div>
      <div
        v-if="hasHeadline"
        class="wpce-hero-image-headline-container"
        :class="[hasText ? 'container' : 'container-wide', !hasText ? alignment : undefined]"
      >
        <HeroHeadline
          class="wpce-hero-image-headline"
          :text="headline"
          :highlight-first-line="headlineFlags?.firstLine ?? false"
          :highlight-last-line="headlineFlags?.lastLine ?? false"
          with-slash
          v-enter
        />
      </div>
    </div>
  </FullscreenImageContainer>
</template>

<style lang="scss" scoped>
.wpce-hero-image {
  --height: min(85%, 85vh);
  --margin: min(7.5%, 7.5vh);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  align-self: flex-start;
  width: 100%;
  height: var(--height);
  margin: var(--margin) 0;

  &.only-headline {
    --height: min(80%, 80vh);
    --margin: min(10%, 10vh);
  }
}

.wpce-hero-image-headline-container {
  margin-top: auto;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;

  &.align-middle {
    margin-top: auto;
    margin-bottom: auto;
  }

  &.align-top {
    margin-top: 0;
    margin-bottom: auto;
  }
}

.wpce-hero-image-content-container {
  margin-left: auto;
  margin-right: auto;

  &.align-middle {
    margin-top: auto;
    margin-bottom: auto;
  }

  &.align-bottom {
    margin-top: auto;
  }
}

.wpce-hero-image-content {
  --padding-x: 2.5rem;
  --padding-y: 3rem;
  background-color: rgb(0 0 0 / 75%);
  color: #fff;

  @include mediaSM {
    --padding-x: 6rem;
    --padding-y: 3rem;
  }
}
</style>
