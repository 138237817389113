export function useSEO(site, post) {
  let lang = computed(() => site.value?.lang ?? 'de_CH')
  let siteName = computed(() => site.value?.site_name ?? 'module+')
  let meta = computed(() => post.value?.seo ?? undefined)
  let noindex = computed(() => meta.value?.noindex ?? false)
  let title = computed(() => meta.value?.title ?? `${post.value?.title ?? 'Wird geladen...'}`)
  let twitterTitle = computed(() => meta.value?.twitter_title ?? meta.value?.title ?? '')
  let ogTitle = computed(() => meta.value?.og_title ?? meta.value?.title ?? '')
  let description = computed(() => meta.value?.description ?? '')
  let twitterDescription = computed(
    () => meta.value?.twitter_description ?? meta.value?.description ?? '',
  )
  let ogDescription = computed(() => meta.value?.og_description ?? meta.value?.description ?? '')
  let noindexString = computed(() => (noindex.value ? 'noindex' : 'index'))

  useHead({
    htmlAttrs: {
      lang: lang.value.replace('_', '-'),
    },
    title,
    meta: [
      // Generic
      { name: 'description', content: description },
      { name: 'robots', content: noindexString },

      // Open Graph
      { name: 'og:locale', content: lang },
      { name: 'og:type', content: 'website' },
      { name: 'og:title', content: ogTitle },
      { name: 'og:description', content: ogDescription },
      { name: 'og:site_name', content: siteName },

      // Twitter
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: twitterTitle },
      { name: 'twitter:description', content: twitterDescription },
    ],
  })
}
