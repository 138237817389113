<script setup>
let props = defineProps({
  image: Array,
  quotes: Array,
})

let { data: imageUrl, pending } = await useWordpressData(`wp-remote/image/url/${props.image[0].id}`)
</script>

<template>
  <div class="wpce-testimonials" v-if="!pending">
    <FullscreenImageContainer class="testimonials-image" :image="imageUrl" />
    <div class="container-wide testimonials-container">
      <div class="testimonial-box" v-for="{ value: quote, id } in quotes" :key="id">
        <figure>
          <blockquote>{{ quote.content }}</blockquote>
          <figcaption>
            <span class="testimonial-name">{{ quote.name }}</span
            ><br />
            <span class="testimonial-company">{{ quote.company }}</span>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wpce-testimonials {
  .testimonials-container {
    display: flex;
    flex-direction: column;
    gap: space(2);
    margin-top: -60vh;
    padding-bottom: 40vh;
  }

  .testimonials-image {
    position: sticky;
    top: 0;
  }

  .testimonial-box {
    --padding-x: 2.5rem;
    --padding-y: 1.5rem;

    position: relative;
    background-color: rgb(0 0 0 / 75%);
    color: #fff;
    padding: var(--padding-y) var(--padding-x);
    z-index: 1;
    margin-left: calc(-1 * var(--container-padding));
    margin-right: calc(-1 * var(--container-padding));

    @include mediaS {
      --padding-x: 3.5rem;
      --padding-y: 2rem;
      margin: 0;
    }

    @include mediaSM {
      max-width: 550px;
      &:nth-child(even) {
        margin-left: auto;
      }
    }

    @include mediaMD {
      --padding-x: 5rem;
      --padding-y: 3rem;
      max-width: min(60vw, 890px);
    }

    blockquote {
      line-height: 1.5;
      white-space: pre-line;
      margin: 0;
      @include h4;

      @include mediaMD {
        @include h3;
      }

      &::before,
      &::after {
        content: '';
        width: 0.62em;
        height: 0.55em;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &::before {
        position: relative;
        left: -0.2em;
        margin-left: -0.62em;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAzMCAyNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjQgMCAxNCAxMy41IDI0IDI3aDZMMjAgMTMuNSAzMCAwaC02Wk0xMCAwIDAgMTMuNSAxMCAyN2g2TDYgMTMuNSAxNiAwaC02WiIgZmlsbD0iI0ZFQ0QyRiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');

        @include mediaSM {
          left: -0.6em;
        }
      }

      &::after {
        margin-left: 0.2em;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAzMCAyNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNiAwIDEwIDEzLjVMNiAyN0gwbDEwLTEzLjVMMCAwaDZabTE0IDAgMTAgMTMuNUwyMCAyN2gtNmwxMC0xMy41TDE0IDBoNloiIGZpbGw9IiNGRUNEMkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
      }
    }

    figure {
      margin: 0;
    }

    figcaption {
      margin-top: space(0.5);
      line-height: 1.35;
    }

    .testimonial-name,
    .testimonial-company {
      font-weight: bold;

      @include mediaSM {
        font-size: 0.9em;
      }
    }

    .testimonial-company {
      color: var(--primary-color);
    }
  }
}
</style>
