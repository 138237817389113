<script setup>
const props = defineProps({
  text: String,
  link: null,
  flags: Object,
})

let { label, url, pending, newTab } = await useWordpressLink(() => props.link)
</script>

<template>
  <ActionTrigger
    v-if="url"
    class="wpce-hero-headline-link"
    :link="url"
    :target="newTab ? '_blank' : null"
  >
    <HeroHeadline
      class="wpce-hero-headline"
      :text="text"
      :link="link"
      :highlight-first-line="flags?.firstLine ?? false"
      :highlight-last-line="flags?.lastLine ?? false"
      with-slash
      v-enter
    />
  </ActionTrigger>
  <HeroHeadline
    v-else
    class="wpce-hero-headline"
    :text="text"
    :link="link"
    :highlight-first-line="flags?.firstLine ?? false"
    :highlight-last-line="flags?.lastLine ?? false"
    with-slash
    v-enter
  />
</template>

<style lang="scss" scoped>
.wpce-hero-headline-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.wpce-hero-headline {
  &:not(:first-child) {
    margin-top: space(1.5);
  }

  &:not(:last-child) {
    margin-bottom: space(0.75);
  }
}
</style>
