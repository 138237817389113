<template>
  <button
    class="menu-button"
    @click="emit('update:menuVisible', !menuVisible)"
    :class="{ 'menu-active': menuVisible }"
  >
    <!-- prettier-ignore -->
    <svg aria-hidden="true" focusable="false" width="23" height="24" viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg"><g fill="#FFCE32" fill-rule="evenodd"><path d="M8.277 7.441h6.919V.522H8.277v6.919ZM9.85 5.867h3.771v-3.77H9.85v3.77Z"/><path d="M22.644 8.818h-8.919v5.344H9.877V8.818H.96v6.92h7.318v7.343h6.92v-7.344h7.447V8.818ZM15.3 14.162h5.771v-3.77h-5.77v3.77Zm-5.45 1.575H13.621v5.77h-3.77v-5.77Zm-7.317-5.345h5.77v3.77h-5.77v-3.77Z"/></g></svg>

    <span class="menu-button-label">Menu</span>
  </button>
</template>

<script setup>
defineProps({
  menuVisible: Boolean,
})

let emit = defineEmits(['update:menuVisible'])
</script>

<style lang="scss" scoped>
.menu-button {
  @include reset-form-ui;

  box-sizing: content-box;
  position: fixed;
  width: 28px;
  height: 28px;
  padding: 13px;
  left: 1rem;
  top: 1rem;
  z-index: 9999;
  appearance: none;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 300ms, background-color 300ms;

  @include mediaSM {
    left: 2rem;
    top: 2rem;
  }

  @include hover {
    background-color: #222;
  }

  @include focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color);
  }

  svg {
    height: inherit;
    width: 100%;
  }

  .menu-button-label {
    position: absolute;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    top: 100%;
    margin-top: 1em;
    text-transform: uppercase;
    font-size: 1.25rem;
    pointer-events: none;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    opacity: 0;
    transition-property: opacity, transform, transform-origin;
    transition-duration: 400ms;
    transform: rotate(270deg);
    transform-origin: center -45px;

    @include mediaSM {
      opacity: 1;
    }

    @include mediaMD {
      transform: rotate(180deg);
      transform-origin: center;
    }
  }

  &.menu-active .menu-button-label {
    opacity: 0;
  }
}
</style>
